// set to height: 100% for child element vertical align center
html,
body,
#root {
	height: 100%;
}

// loader screen
.loader {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	z-index: 9999;
	background: $loader 50% 50% no-repeat rgb(0, 0, 0, 0.2);
}

// Error message for form input validation
.error-message {
	color: #ff0000;
	margin: 0;
	font-size: small;

	&::before {
		display: inline;
		content: '⚠ ';
	}
}

p {
	margin: 0;
}
