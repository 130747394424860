/*!

 =========================================================
 * Light Bootstrap Dashboard React - v2.0.0
 =========================================================

 * Product Page: http://www.creative-tim.com/product/light-bootstrap-dashboard-react
 * Copyright 2020 Creative Tim (http://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'lbd/variables';
@import 'lbd/mixins';

@import 'lbd/typography';

// Core CSS
@import 'lbd/misc';
@import 'lbd/sidebar-and-main-panel';
@import 'lbd/buttons';
@import 'lbd/inputs';

@import 'lbd/alerts';
@import 'lbd/tables';

@import 'lbd/checkbox-radio-switch';
@import 'lbd/navbars';
@import 'lbd/footers';

// Fancy Stuff
@import 'lbd/plugins/animate';
@import 'lbd/dropdown';
@import 'lbd/cards';
@import 'lbd/responsive';
@import 'lbd/bootstrap-switch';

// Nucleo Icons
@import 'lbd/partial-nucleo-icons';

// React Differences
@import 'lbdr/react-differences';

// import bootstrap & overwrite if not using any template
// @import 'overwrite-bootstrap';
// @import 'bootstrap';

// Own scss
@import 'self/variables';
@import 'self/global';
@import 'self/components';
@import 'self/pages/login';
